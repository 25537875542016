import axios from "axios";
import config from "@config";

const VIEWER_LOADING = "VIEWER_LOADING";
const VIEWER_SERVER_ERROR = "VIEWER_SERVER_ERROR";
const VIEWER_DONE = "VIEWER_DONE";

const VIEWER_SET = "VIEWER_SET";

const DOCUMENT_SET = "DOCUMENT_SET";
const PAGE_SET = "PAGE_SET";

const initialState = {
  server_error: false,
  loading: false,
  viewer: null,
  document: null,
  documents: [],
  pages: {},  
  currentPage: null, // Current Page
  id: null // Document Id
};

// REDUCERS --- : Handle the result of the actions

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEWER_LOADING:
      return {
        ...state,
        server_error: false,
        loading: true
      };
    case VIEWER_DONE:
      return {
        ...state,
        server_error: false,
        loading: false
      };
    case VIEWER_SET:
      return {
        ...state,
        viewer: action._viewer,
        server_error: false,
        loading: false
      };
    case PAGE_SET:            
      const __page = state.pages;
      __page[action._page] = action._data;      
      return {
        ...state,
        pages: __page,
        currentPage: action._page,
        server_error: false,
        loading: false
      };
    case DOCUMENT_SET:      
      return {
        ...state,
        document: action._document,
        id: action._document_id,        
        server_error: false,
        loading: false
      };
    
    case VIEWER_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        server_error: true
      };
    default:
      return state;
  }
};

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

function loading() {
  return { type: VIEWER_LOADING };
}

function handleErrors(response) {  
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

function serverError() {
  return { type: VIEWER_SERVER_ERROR, response: { success: false } };
}

function setDocument(_data, _document_id) {
  return { type: DOCUMENT_SET, _document: _data, _document_id };
}


export function getDocument(_document_id, _token = null) {
  return dispatch => {    
    return axios({
      method: "GET",
      url: `${config.API_URL}/documents/${_document_id}`,
      json: true
    })
      .then(response => {
        if (response.status === 200) {
          return dispatch(setDocument(response.data, _document_id));
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
}

export function getDocument2(_document_id, _token = null) {  
  console.log("_document_id", _document_id)
  console.log("_token", _token)
  return dispatch => {        
    fetch(`${config.API_URL}/documents/${_document_id}`, {
      method: 'GET',      
      mode: "cors"
    })
    .then(handleErrors)
    .then(response => response.json())
    .then(data => { dispatch({ type: DOCUMENT_SET, document: data.Document }); })         
    .catch((error) => {
      console.error('Problem with your fetch operation:', error);
      dispatch({ type: VIEWER_SERVER_ERROR  });
    });
  }
}

export function getPage(_document_id, _page, _token = null) {
  return dispatch => {    
    fetch(`${config.API_URL}/documents/${_document_id}/page/${_page}`, {
      method: 'GET',              
      headers: {        
        'Accept': 'text/html',       
      },              
    })
    .then(handleErrors)
    .then(response => response.text())
    .then(_data => { dispatch({ type: PAGE_SET, _data, _page }); })         
    .catch((error) => {
      console.error('Problem with your fetch operation:', error);
      dispatch({ type: VIEWER_SERVER_ERROR  });
    });
  }
}