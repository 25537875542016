import config from '@config'
import axios from "axios";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const LOGIN_ERROR = "LOGIN_ERROR";

const initialState = {
  loading: false,
  alert: null,
  user: null,
  token: null
};

// REDUCERS --- : Handle the result of the actions

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:            
      return {
        ...state,
        loading: false,
        alert: {
          success: true,
          msg: "Succesfully Loged In",
        },
        user: action.data.user,
        token: action.data.token
      };
    case LOGOUT:            
      return initialState;

    case LOGIN_ERROR:            
      return {
        ...state,
        loading: false,        
        alert: {
          success: false,
          msg: "Wrong Credentials",
        },
      };    
    default:
      return state;
  }
};

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

function handleErrors(response) {  
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export function logout() {
  return { type: LOGOUT }
}

export function login2(_data) {
  return dispatch => {    
    fetch(`${config.API_URL}/users/sign_in`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(_data) // body data type must match "Content-Type" header
    })
    .then(handleErrors)
    .then(response => response.json())
    .then(myJson => { dispatch({ type: LOGIN, data: myJson });})
    .catch((error) => {
      console.error('Problem with your fetch operation:', error);
      dispatch({ type: LOGIN_ERROR  });
    });
  }  
}

export function login(_data) {    
  return dispatch => {
    // dispatch(actionLoading("login"));
    return axios({
      method: "POST",
      // url: `${env.REACT_APP_API_URL}/users/sign_in`,
      url: "https://www.test.wingpaper.com/v1/users/sign_in",
      data: _data,
      headers: {
        // 'Accept': 'application/json',
        "Content-Type": "application/json"
      },
      // json: true
    })
      .then(response => {
        if (response.status === 200) {
          console.log("response", response);
          // return dispatch(responseLogin(response.data));
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch({ type: LOGIN_ERROR  });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };  
}