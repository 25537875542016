import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import viewer from '@components/viewer/viewer.duck';
import auth from '@components/auth/auth.duck';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  viewer: viewer,
  auth: auth
})
export default createRootReducer